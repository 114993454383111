.heroSection {
  display: flex;
  text-align: center;
  justify-content: center;
  background-color: black;
  height: 99.8vh;
}

.video {
  width: 100%;
  object-fit: cover;
}

.container {
  background-image: url("../../assets/images/excerciseAtlas-application-section-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.overlay {
  position: relative;
  background-color: transparent;
  background-image: linear-gradient(
    50deg,
    var(--main-color) 0%,
    #ffffff00 100%
  );
}

.contentContainer {
  padding: 3rem 10%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.sectionDivider {
  position: absolute;
  width: 50%;
  top: 5%;
  left: 25%;
}

.appImg {
  width: 100%;
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.title {
  color: var(--main-color);
  font-size: 6rem;
  text-align: center;
  line-height: 1;
  margin-bottom: 3rem;
}

.titleDivider {
  width: 45%;
  align-self: flex-end;
  margin-bottom: 3rem;
}

.desc {
  color: #fff;
  font-size: 1.6rem;
  text-align: justify;
  margin-bottom: 4rem;
}

.button {
  align-self: flex-end;
  margin-bottom: 3rem;
}

.textAvailbility {
  font-size: 1.6rem;
  font-weight: bold;
  color: #fff;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.shopContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}

.storeLogo {
  width: 100%;
}

.link {
  display: flex;
  width: 20rem;
}

@media screen and (max-width: 1380px) {
  .container {
    background-repeat: repeat-y;
    background-size: cover;
    background-attachment: unset;
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8rem 3rem;
  }

  .appImg {
    width: 50%;
  }

  .title {
    font-size: 4rem;
  }

  .desc {
    font-size: 1.6rem;
  }

  .shopContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .textAvailbility {
    font-size: 4rem;
    text-align: center;
    line-height: 1;
  }

  .link {
    justify-content: center;
  }

  .storeLogo {
    width: 60%;
  }

  .link {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .appImg {
    width: 100%;
  }
}

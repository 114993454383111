.container {
  display: grid;
  grid-template-columns: 2fr 3fr;
}

.productThumbnail {
  display: flex;
  width: 100%;
  border-radius: 2rem;
  align-self: center;
}

.productDescContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 1rem 0;
}

.unitPrice {
  color: #000;
  margin-top: 2rem;
  margin: 0;
  font-weight: 700;
  justify-self: center;
}

.productName {
  color: #000;
  font-size: 1.6rem;
  font-weight: 700;
}

.property {
  color: #000;
  font-size: 1.4rem;
}

@media screen and (max-width: 769px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .properties {
    text-align: center;
  }
}

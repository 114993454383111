.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  max-width: 100%;
  height: 40rem;
  overflow: hidden;
}

.crossFadingImg {
  position: absolute;
  object-fit: contain;
  animation: move 50s ease infinite, fade 30s ease-in-out infinite;
  width: 100%;
}

hr {
  height: 2px;
  background-color: var(--main-color);
  border: none;
  z-index: 1000;
}

.firstDivider {
  position: absolute;
  width: 35%;
  top: 12rem;
  left: 0;
}

.secondDivider {
  position: absolute;
  width: 16%;
  top: 8rem;
  left: 120rem;
}

.thirdDivider {
  position: absolute;
  width: 10%;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  gap: 3rem;
}

.motto {
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  padding-left: 25rem;
  text-shadow: #000 1px 0px 10px;
}

.pageTitle {
  position: relative;
  font-size: 7.8rem;
  font-weight: bold;
  color: var(--main-color);
  margin: 0;
  padding: 0;
  line-height: 0;
  text-shadow: #000 1px 0px 10px;
}

.firstImageDelay {
  animation-delay: 0s;
}

.secondImageDelay {
  animation-delay: 15s;
}

@keyframes move {
  0% {
    transform-origin: center;
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  34% {
    opacity: 0;
  }
  55% {
    opacity: 0;
  }
  77% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.fadeInLeft {
  visibility: hidden;
}

.fadeInLeftDelay {
  display: block;
  visibility: hidden;
  width: 100%;
  height: 100%;
}

.fadeInLeftAnimation {
  animation: fadeInLeft 1s ease-out forwards;
}

.fadeInLeftAnimationDelay {
  animation: fadeInLeft 1s 500ms ease-out forwards;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
}

@media screen and (max-width: 767px) {
  .container {
    justify-content: center;
  }

  .motto {
    padding-left: 0;
    padding-bottom: 2rem;
  }

  .crossFadingImg {
    position: absolute;
    top: 0;
    left: 0;
    height: inherit;
    object-fit: cover;
    animation: move 50s ease infinite, fade 20s ease-in-out infinite;
  }

  .firstDivider {
    display: none;
  }

  .secondDivider {
    display: none;
  }

  .thirdDivider {
    display: none;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 10%;
  background-image: url("../../assets/images/home_benefits_section_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: calc(100vh - 42.3rem);
}

.descContainer {
  margin-bottom: 5rem;
}

.title {
  color: var(--main-color);
  font-size: 7.8rem;
  margin-top: 2rem;
}

.desc {
  color: #fff;
  font-size: 1.6rem;
}

.sectionDivider {
  position: absolute;
  margin-top: 1rem;
  width: 30%;
  right: 10%;
}

@media screen and (max-width: 1380px) {
  .container {
    padding: 2rem 5%;
  }

  .title {
    font-size: 4rem;
    text-align: left;
  }
}

.container {
  position: relative;
  display: flex;
  background-image: url("../../assets/images/home_inercise_section_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  z-index: 1000;
}

.sectionDivider {
  position: absolute;
  z-index: 2;
  width: 38%;
  height: 2px;
}

.content {
  display: flex;
  justify-content: center;
  z-index: 1;
  min-width: 100%;
  width: auto;
  margin: 6rem 0rem 6rem 0rem;
}

.overlay {
  position: absolute;
  background-color: black;
  height: 100%;
  width: 100%;
  top: 0;
  opacity: 0.89;
}

.firstElement {
  display: block;
  max-width: 33%;
}

.secondElement {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 34%;
  width: auto;
  overflow-y: visible;
  z-index: 1000;
  margin-top: -8rem;
  visibility: hidden;
}

.thirdElement {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width: 33%;
}

.app {
  margin-top: 20rem;
  margin-left: -52%;
  transition: all 0.2s ease-in-out;
}

.app:hover {
  transform: scale(1.1);
}

.orderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-end;
  margin-right: 25%;
  margin-bottom: 5rem;
}

.oldPrice {
  color: var(--main-color);
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
}

.newPrice {
  color: #fff;
  font-size: 7.8rem;
  margin: 0;
  line-height: 1;
  padding-bottom: 2%;
}

.inercise {
  display: block;
  width: 150%;
  transition-duration: 0.3s;
}

.inercise:hover {
  transform: scale(1.1);
}

.titleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 100%;
}

.subtitle {
  font-size: 2rem;
  color: #ffffff;
  margin: 0;
}

.title {
  font-size: 7.8rem;
  color: var(--main-color);
  margin: 0 0 2rem 0;
  line-height: 1;
}

.handler {
  margin-left: 40%;
  margin-top: 5rem;
  transition: all 0.2s ease-in-out;
}

.handler:hover {
  transform: scale(1.1);
}

.inerciseSet {
  margin-left: 15%;
  margin-top: 0%;
  transition: all 0.2s ease-in-out;
}

.inerciseSet:hover {
  transform: scale(1.1);
}

.fadeUpAnimation {
  animation: fadeUp 750ms ease-in forwards;
}

@keyframes fadeUp {
  0% {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  100% {
    transform: none;
    visibility: visible;
  }
}

.fadeUpTitle {
  display: block;
  visibility: hidden;
}

.fadeFromCenterLeft {
  display: block;
  visibility: hidden;
}

.fadeFromCenterLeftAnimation {
  animation: fadeFromCenterLeft 1.5s ease-out 750ms forwards;
}

.fadeFromCenterLeftDelay {
  display: block;
  visibility: hidden;
}

.fadeFromCenterLeftDelayAnimation {
  animation: fadeFromCenterLeft 1.5s ease-out 1s forwards;
}

@keyframes fadeFromCenterLeft {
  0% {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  100% {
    transform: none;
    visibility: visible;
  }
}

.fadeCenterRight {
  visibility: hidden;
}

.fadeCenterRightAnimation {
  animation: fadeCenterRight 1.5s ease-out 750ms forwards;
}

@keyframes fadeCenterRight {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: none;
    visibility: visible;
  }
}

.fadeOpacity {
  display: block;
  visibility: hidden;
}

.fadeOpacityAnimation {
  animation: fadeOpacity 1s ease-in forwards;
}

@keyframes fadeOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

.titleDivider {
  position: relative;
  width: 70%;
}

.fadeInLeft {
  display: block;
  visibility: hidden;
  width: 100%;
  margin-left: 90%;
}

.fadeInLeftAnimation {
  animation: fadeInLeft 1s 1s ease-in forwards;
}

@keyframes fadeInLeft {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: none;
    visibility: visible;
  }
}

@media screen and (max-width: 1380px) {
  .container {
    background-repeat: repeat-y;
    background-attachment: unset;
  }

  .content {
    padding: 0 2%;
  }

  .inerciseSet {
    display: none;
  }

  .handler {
    display: none;
  }

  .app {
    display: none;
  }

  .titleContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 100%;
  }

  .title {
    font-size: 4rem;
    text-align: center;
    margin-bottom: 1rem;
  }

  .subtitle {
    font-size: 2rem;
    text-align: center;
  }

  .titleDivider {
    position: relative;
    width: 50%;
    margin: 0 0;
    margin-left: 0%;
  }

  .inercise {
    height: 110%;
  }

  .thirdElement {
    display: flex;
    justify-content: space-between;
    min-width: 34%;
  }

  .orderContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    flex-grow: 1;
    margin-right: 15%;
  }

  .newPrice {
    font-size: 5rem;
    align-self: flex-end;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 767px) {
  .container {
    max-height: none;
    justify-content: flex-start;
    align-self: flex-start;
    align-items: normal;
    background-position: center;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5rem;
  }

  .firstElement {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100%;
  }

  .title {
    font-size: 5rem;
  }

  .secondElement {
    display: flex;
    padding: 0;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  .appContainer {
    margin-top: 3rem;
  }

  .titleContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }

  .titleDivider {
    position: relative;
    width: 25%;
    margin: 2% 0;
    left: 20%;
  }

  .inercise {
    width: 95%;
  }

  .orderContainer {
    margin: 0;
    justify-content: center;
    align-items: center;
  }

  .newPrice {
    padding: 0;
    text-align: center;
    padding-bottom: 5%;
  }
}

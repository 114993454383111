.container {
  display: block;
  max-width: 90%;
  width: 100%;
  margin: auto;
  margin-bottom: 5rem;
}

.titleContainer {
  padding: 0 20%;
  color: #000;
  padding-bottom: 2rem;
  text-align: center;
}

.title {
  font-size: 3em;
  font-weight: 700;
  color: var(--main-color);
  margin-bottom: 1rem;
}

.articleInfo {
  font-size: 1.4rem;
  font-weight: 400;
}

.text {
  font-size: 1.8rem;
  font-weight: 500;
  color: #000;
  padding: 0 20%;
  line-height: 2;
  white-space: pre-line;
}

@media screen and (max-width: 1380px) {
  .titleContainer {
    padding: 0;
    padding-top: 2rem;
    margin-bottom: 2rem;
  }

  .title {
    padding: 0;
  }

  .text {
    padding: 0;
  }
}

@media screen and (max-width: 767px) {
  .title {
    font-size: 1.8rem;
  }

  .text {
    font-size: 1.6rem;
  }
}

.image-gallery-thumbnail.active {
  border: 4px solid var(--main-color);
  border-radius: 2rem;
}

.image-gallery-thumbnail:hover {
  border: 4px solid var(--main-color);
  border-radius: 2rem;
}

.image-gallery-icon:hover {
  color: var(--main-color);
}

.image-gallery-thumbnail-image {
  border-radius: 1.5rem;
}

.image-gallery-image {
  border-radius: 2rem;
}

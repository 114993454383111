.container {
  display: block;
}

.title {
  font-weight: bold;
  color: #000000;
  font-size: 2rem;
  margin-bottom: 2rem;
}

.links {
  margin-top: 0;
}

.linksList {
  padding-bottom: 0.2rem;
}

.linksList {
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
}

@media screen and (max-width: 767px) {
  .container {
    width: 100%;
  }
}

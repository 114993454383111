.container {
  display: flex;
  background-image: url("../../assets/images/inercise_feature_section_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding: 3rem 2%;
  min-height: 50vh;
  margin: auto;
  flex-direction: column;
  align-items: center;
}

.divider {
  width: 70%;
  margin-left: -35%;
}

.title {
  color: var(--main-color);
  text-align: center;
  font-size: 5.4rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.ordersContainer {
  display: flex;
  width: 100%;
  align-items: flex-start;
}

.orderContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 32rem;
  background-color: #fff;
  border-radius: 2rem;
  padding: 2rem;
  width: 60%;
  margin-bottom: 4rem;
}

.productOrderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.propertyValue {
  display: inline;
  font-weight: 500;
}

.marginRight {
  margin-right: 3rem;
}

.infoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5rem;
}

.textEnd {
  text-align: end;
}

.propertyTypeValue {
  display: inline-block;
}

.orderTitle {
  font-size: 2rem;
  color: #000;
  font-weight: 700;
}

.orderStatusText {
  font-size: 1.6rem;
  color: #000;
}

.productThumbnail {
  width: 100%;
  max-width: 40rem;
  border-radius: 2rem;
}

.text {
  color: #000;
  font-size: 1.6rem;
}

.productName {
  color: #000;
  font-size: 1.6rem;
}

.addressTitle {
  font-weight: 700;
}

@media screen and (max-width: 1380px) {
  .productOrderContainer {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
  }

  .orderContainer {
    width: 100%;
  }
  .title {
    font-size: 4rem;
  }

  .propertiesContainer {
    margin-bottom: 2rem;
  }

  .text {
    text-align: center;
  }

  .marginRight {
    margin-right: 0rem;
  }
}

@media screen and (max-width: 767px) {
  .productOrderContainer {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
  }

  .orderTitle {
    font-size: 1.8rem;
    text-align: center;
  }

  .title {
    font-size: 4rem;
  }

  .infoContainer {
    flex-direction: column;
    gap: 2rem;
  }

  .orderStatusText {
    text-align: center;
  }

  .propertiesContainer {
    margin-bottom: 2rem;
    align-self: center;
  }

  .text {
    text-align: center;
  }
}

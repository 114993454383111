.listItem {
  padding-bottom: 0.2rem;
  margin: 0;
}

.link {
  text-decoration: none;
  color: #000;
  font-weight: 600;
  font-size: 1.6rem;
  margin: 0;
}

.container {
  display: block;
  width: 100%;
}

.contentContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
  padding: 5rem 15%;
}

.overlay {
  background-color: transparent;
}

.video {
  width: 100%;
}

@media screen and (max-width: 1380px) {
  .contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 2.5rem 5%;
  }

  .container {
    background-color: #fff;
  }
}

.container {
  position: relative;
  display: flex;
  overflow: hidden;
  margin: 0;
  background-image: url("../../assets/images/home_blog_section_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding-bottom: 7.5rem;
}

.content {
  display: block;
  margin: auto;
  z-index: 1;
  width: 85%;
}

.firstElement {
  margin-top: 4rem;
  visibility: hidden;
}

.sectionDivider {
  position: absolute;
  z-index: 2;
  width: 60%;
  left: 7%;
}

hr {
  margin: 0;
  background-color: var(--main-color);
  border: none;
  height: 2px;
}

.blog {
  font-size: 6.4rem;
  color: var(--main-color);
  margin: 0;
  margin-bottom: 10px;
}

.titleDivider {
  z-index: 2;
  width: 13%;
  left: 7%;
  margin-left: 5%;
}

.secondElement {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 4rem;
  margin-top: 4rem;
  visibility: hidden;
}

.fadeInLeftAnimationDelay {
  animation: fadeInLeft 1s 750ms ease forwards;
}

@keyframes fadeInLeft {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: none;
    visibility: visible;
  }
}

.fadeOpacityAnimation {
  animation: fadeOpacity 750ms ease-in forwards;
}

@keyframes fadeOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@media screen and (max-width: 1380px) {
  .container {
    padding-bottom: 3rem;
    background-repeat: repeat-y;
    background-attachment: unset;
    background-position: center;
    background-image: url("../../assets/images/home_blog_section_mobile_bg.jpg");
  }

  .content {
    width: auto;
    margin: 0 3rem 2rem 3rem;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding-bottom: 3rem;
    background-repeat: repeat-y;
    background-attachment: unset;
    background-position: center;
  }

  .secondElement {
    display: block;
  }

  .titleDivider {
    z-index: 2;
    width: 80%;
  }
}

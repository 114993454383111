.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 42.3rem);
}

.title {
  font-size: 6rem;
  color: #000;
  margin-bottom: 2rem;
  text-align: center;
}

.button {
  margin-bottom: 3rem;
  text-align: center;
  width: 30rem;
}

.button:hover {
  box-shadow: 0 0.35rem 2.5rem #f42c3740;
}

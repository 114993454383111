.container {
  display: grid;
  grid-template-columns: 4fr 1fr 0.7fr 0.5fr;
  width: 100%;
  background-color: #fff;
  border-radius: 2rem;
  gap: 2rem;
  align-items: center;
  margin-bottom: 2rem;
  padding: 3rem;
}

.priceInfo {
  color: #000;
  font-size: 1.6rem;
  margin-top: 2rem;
  margin: 0;
  font-weight: 700;
  justify-self: center;
}

.closeIcon {
  cursor: pointer;
}

.unitPrice {
  font-size: 1.6rem;
}

@media screen and (max-width: 769px) {
  .container {
    display: flex;
    flex-direction: column;
    box-shadow: #84ccd1 0px 0px 19px 0px;
  }
}

.paymentContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 75%;
  width: 20%;
  background-color: #fff;
  border-radius: 2rem;
  padding: 2rem;
  margin-left: 2rem;
}

.valueContainer {
  display: flex;
  justify-content: space-between;
}

.cartTag {
  color: #000;
  font-size: 1.4rem;
}

.cartValue {
  color: #000;
  font-size: 1.6rem;
}

.cartTag {
  color: #000;
  font-size: 1.4rem;
}

.divider {
  width: 80%;
  margin-left: -65%;
}

.button {
  margin-top: 2rem;
  align-self: center;
}

.error {
  display: block;
  color: red;
  font-size: 1.4rem;
  align-self: flex-start;
  align-self: center;
  text-align: center;
}

.infoText {
  text-align: center;
  margin-top: 1rem;
}

.infoAboutPaymentInEuro {
  font-size: 1.2rem;
  font-weight: 600;
}

.buttonEur {
  align-self: center;
  margin-top: 1rem;
}

.deliveryTag {
  font-size: 1.2rem;
}

.deliveryValue {
  font-size: 1.2rem;
  font-weight: 700;
}

@media screen and (max-width: 1380px) {
  .paymentContainer {
    position: relative;
    left: 0;
    width: 80%;
    background-color: #fff;
    border-radius: 2rem;
    padding: 2rem;
    margin-right: 0;
    margin-top: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .paymentContainer {
    box-shadow: #84ccd1 0px 0px 19px 0px;
  }

  .button {
    width: 90%;
  }
}

.container {
  min-height: 100vh;
  margin: 5rem 10%;
}

.productsListContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 2rem;
}

.productContainer {
  position: relative;
  display: grid;
  grid-template-columns: 45% 60%;
  gap: 5rem;
  margin-bottom: 10rem;
}

.leftBtn {
  position: absolute;
  right: 90%;
  border: none;
  background-color: transparent;
  font-size: 3rem;
  font-weight: 800;
  cursor: pointer;
  color: #fff;
}

.rightBtn {
  position: absolute;
  left: 90%;
  border: none;
  background-color: transparent;
  font-size: 3rem;
  font-weight: 800;
  cursor: pointer;
  color: #fff;
}

.productImage {
  width: 100%;
  height: 45rem;
  object-fit: cover;
  border-radius: 1.25rem;
}

.imagesGallery {
  position: relative;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 30% 30% 30%;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  width: 100%;
  gap: 1.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  overflow: hidden;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.galleryThumbnail {
  display: flex;
  width: 100%;
  height: 15rem;
  object-fit: fill;
  border-radius: 1rem;
}

.galleryThumbnailBorder {
  display: flex;
  width: 100%;
  height: 15rem;
  object-fit: fill;
  border-radius: 1rem;
  border: 3px solid var(--main-color);
  box-shadow: var(--main-color) 0px 0px 10px 0px;
}

.descContainer {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 3.8rem;
  color: #000;
  font-weight: 700;
  padding-bottom: 1rem;
}

.currentPrice {
  font-size: 3rem;
  color: var(--main-color);
  font-weight: 700;
  line-height: 2.4rem;
  padding-bottom: 4rem;
}

.previousPrice {
  font-size: 1.6rem;
  color: #000;
  font-weight: 700;
  line-height: 2.4rem;
  padding-bottom: 1rem;
}

.desc {
  font-size: 1.6rem;
  color: #000;
  font-weight: 500;
  line-height: 2.4rem;
  padding-bottom: 2rem;
}

.availableColors {
  font-size: 1.6rem;
  color: #000;
  font-weight: 700;
}

.techDescContainer {
  grid-column-end: span 2;
}

.table {
  width: 60%;
  margin: auto;
}

.header {
  background-color: var(--main-color);
  color: #fff;
  font-weight: 400;
  font-size: 1.6rem;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  padding: 0.5rem 0rem 0.5rem 1.5rem;
  text-align: start;
}

.table td {
  color: #7a7a7a;
  font-size: 1.6rem;
  padding: 0.5rem 0rem 0.5rem 1.5rem;
  font-weight: 400;
}

.table tr:nth-child(2n) {
  background-color: #f3f3f3;
  color: #fff;
}

.productPropertiesContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-content: center;
  margin-bottom: 2rem;
}

.colorBtn {
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
  border: 2px solid var(--main-color);
}

.button {
  justify-self: flex-end;
  margin-right: 3rem;
  max-width: 20rem;
  width: 100%;
}

.tpayInstallmentsLink {
  display: flex;
  justify-content: center;
}

.tpayInstallmentsImg {
  width: 100%;
  object-fit: cover;
}

.paymentContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-top: 2rem;
}

.disabled {
  opacity: 0.1;
  cursor: default !important;
}

.propertyButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  border-radius: 2rem;
  border: 0.2rem solid var(--main-color);
  margin-right: 1rem;
  cursor: pointer;
}

.propertyButtonSelected {
  box-shadow: #84ccd1 0px 0px 10px 5px;
}

.sizePropertyButton {
  color: #000;
  font-size: 1.4rem;
  font-weight: 700;
}

.sizeInerdysk5Button {
  color: #000;
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--main-color);
}

.sizeInerdysk10Button {
  color: #fff;
  font-size: 1.4rem;
  font-weight: 700;
  background-color: #00ab1d;
}

.sizeInerdysk25Button {
  color: #fff;
  font-size: 1.4rem;
  font-weight: 700;
  background-color: #f4d137;
}

.sizeInerdysk50Button {
  color: #fff;
  font-size: 1.4rem;
  font-weight: 700;
  background-color: #01c7fd;
}

.propertyText {
  color: #000;
  font-size: 1.6rem;
  margin-right: 1.5rem;
  font-weight: 600;
  width: 18rem;
}

.deliveryText {
  width: auto;
}

.propertyContainers {
  display: flex;
  align-items: center;
}

.productTitle {
  font-size: 4rem;
  color: var(--main-color);
  margin: 0;
  line-height: 1;
  margin-bottom: 1rem;
}

@media screen and (max-width: 1380px) {
  .productContainer {
    display: flex;
    flex-direction: column;
  }

  .tpayInstallmentsLink {
    display: flex;
    width: 80%;
  }
}

@media screen and (max-width: 767px) {
  .productContainer {
    margin-bottom: 1rem;
    gap: 1rem;
  }

  .productPropertiesContainer {
    flex-direction: column;
    margin-bottom: 1rem;
    gap: 0.5rem;
  }

  .container {
    margin: 2rem;
  }

  .productsListContainer {
    display: flex;
    flex-direction: column;
  }

  .propertyContainers {
    margin-bottom: 2rem;
  }
  .table {
    width: 100%;
    margin: auto;
  }

  .paymentContainer {
    flex-direction: column;
  }

  .button {
    margin: 0;
    margin-bottom: 2rem;
    width: 80%;
  }

  .tpayInstallmentsLink {
    display: flex;
    width: 100%;
    margin-bottom: 4rem;
  }

  .propertyText {
    font-size: 1.2rem;
    width: 10rem;
  }

  .deliveryText {
    width: 100%;
  }

  .productTitle {
    font-size: 3rem;
    text-align: center;
    margin-top: 6rem;
  }

  .header {
    text-align: center;
  }
}

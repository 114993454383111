.container {
  display: flex;
  max-width: 90%;
  width: 100%;
  margin-bottom: 5rem;
  max-width: 160rem;
  justify-content: center;
}

.heroImage {
  width: auto;
  border-radius: 2rem;
  object-fit: fill;
  max-height: 50vh;
}

@media screen and (max-width: 1380px) {
  .container {
    margin: 0;
    padding: 1rem 5%;
  }

  .heroImage {
    border-radius: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .heroImage {
    width: 100%;
    border-radius: 2rem;
    object-fit: fill;
    max-height: 50vh;
  }
}

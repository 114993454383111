.item {
  float: left;
  padding-right: 2rem;
}

.link {
  text-decoration: none;
  color: #000;
  font-size: 1.25rem;
  font-weight: 400;
}

.container {
  position: relative;
  display: block;
  border-radius: 2rem;
  background-color: #fff;
  padding: 5rem 4rem;
  width: 50%;
}

.container:before {
  position: absolute;
  content: "";
  background: url("https://tpay.com/img/banners/tpay_com_zaufane_platnosci_bar_space.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 25%;
  width: 20rem;
  height: 10rem;
  top: -5rem;
  right: 2rem;
}

.subtitle {
  color: #000;
  font-size: 2rem;
  margin-bottom: 2rem;
}

.methodsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1rem;
  row-gap: 1rem;
  justify-items: center;
}

.paymentMethod {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: solid 0.25rem var(--main-color);
  border-radius: 2rem;
  cursor: pointer;
  padding: 1rem 0;
  width: 80%;
  min-height: 10rem;
}

.paymentImage {
  width: 50%;
  object-fit: contain;
}

.paymentMethodText {
  color: #000;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
}

.paymentMethodChecked {
  box-shadow: #84ccd1 0px 0px 19px 0px;
}

@media screen and (max-width: 1380px) {
  .container {
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 90%;
    padding-left: 0;
    padding-right: 0;
    box-shadow: #84ccd1 0px 0px 19px 0px;
  }

  .methodsContainer {
    grid-template-columns: 1fr 1fr;
    column-gap: 0rem;
    row-gap: 1rem;
  }

  .paymentMethod {
    padding: 1rem 0;
    width: 90%;
    height: 10rem;
  }

  .subtitle {
    margin-left: 1.5rem;
  }
}

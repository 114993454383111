.container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--main-color);
  border-radius: 2rem;
  width: 40rem;
  top: 3.5rem;
  right: 0%;
  padding: 3rem 3rem;
  border: none;
  cursor: auto;
}

.emptyCartContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon {
  filter: invert(100%) grayscale(100%);
  margin-bottom: 1rem;
}

.text {
  display: flex;
  justify-content: space-between;
  color: #000;
  font-size: 1.4rem;
}

.productContainer {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr 10fr 0.5fr;
  border-radius: 2rem;
  padding: 1rem 1rem;
  background-color: #fff;
  margin-bottom: 1rem;
  align-items: center;
}

.button {
  background-color: #000;
  width: auto;
}

.closeImage {
  width: 1.6rem;
  cursor: pointer;
  align-self: flex-start;
}

.divider {
  background-color: #fff;
  margin: 1rem auto;
}

.priceContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.priceText {
  color: #000;
  font-size: 1.6rem;
}

.quantity {
  display: block;
  margin: auto;
}

.itemName {
  font-size: 1.2rem;
}

.itemUnitPrice {
  font-size: 1.2rem;
}

.itemPropertyyy {
  font-size: 0.5rem;
}

@media screen and (max-width: 1380px) {
  .container {
    display: none;
  }
}

.container {
  display: grid;
  grid-template-columns: 3fr 1fr;
  align-items: center;
  max-width: 75%;
}

.descContainer {
  background-color: var(--main-opacity-color);
  margin-bottom: 10rem;
  border-radius: 2rem;
  padding: 2rem;
  transition: background-color 0.25s ease-out;
}

.title {
  color: #fff;
  font-size: 3.8rem;
}

.imgTitle {
  max-width: 70%;
}

.desc {
  color: #fff;
  font-size: 1.6rem;
  white-space: pre-line;
  font-weight: 400;
  text-align: justify;
  margin-right: 5rem;
}

.descDivider {
  margin-top: 2.5rem;
  width: 30%;
}

.socialIconsContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 5rem;
  margin-top: 2.5rem;
  margin-bottom: 2rem;
}

.socialMediaLink {
  display: flex;
  text-decoration: none;
  background-color: var(--main-color);
  color: #fff;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  border-radius: 2.5rem;
}

.smIcon {
  width: 2.5rem;
  height: 2.5rem;
}

.img {
  margin-left: -5rem;
  transition: transform 0.25s ease-out;
}

.img:hover {
  transform: scale(1.1);
}

.descContainer:hover {
  background-color: var(--bg-off-color);
}

.imgContainerMobile {
  display: none;
}

.socialMediaDividerContainer {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1380px) {
  .container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }

  .title {
    font-size: 2rem;
    text-align: center;
  }

  .desc {
    text-align: left;
    margin-right: 0;
  }

  .descContainer {
    display: flex;
    flex-direction: column;
    transition: none;
    margin-bottom: 5rem;
  }

  .imgContainer {
    display: none;
  }

  .imgContainerMobile {
    display: flex;
    justify-content: center;
  }

  .img {
    margin-left: 0;
    margin: 4rem 0 4rem 0;
    align-self: center;
  }

  .socialMediaDividerContainer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end;
  }

  .socialMediaDivider {
    display: block;
    position: relative;
  }

  .descDivider {
    margin-left: 35%;
  }

  .img:hover {
    transform: none;
  }

  .descContainer:hover {
    background-color: var(--main-opacity-color);
  }
}

.container {
  display: block;
  box-sizing: border-box;
}

.title {
  font-weight: bold;
  color: #000000;
  font-size: 2rem;
  margin-bottom: 2rem;
}

.address {
  color: #000;
  font-weight: 400;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .container {
    width: 100%;
  }
}

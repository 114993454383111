.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 17rem;
  margin-bottom: 17rem;
}

.title {
  font-size: 6rem;
  color: var(--main-color);
  margin-bottom: 2rem;
  text-align: center;
}

.info {
  color: #000;
  font-size: 2rem;
  margin-bottom: 4rem;
  text-align: center;
}

.button {
  border: none;
  background-color: var(--main-color);
  color: #fff;
  overflow: hidden;
  font-size: 1.6rem;
  cursor: pointer;
  border-radius: 2.4rem;
  padding: 1rem 4rem;
  text-decoration: none;
}

.button:hover {
  box-shadow: 0 0.35rem 2.5rem #f42c3740;
}

@media screen and (max-width: 768px) {
  .container {
    margin: 7rem auto;
  }
  .title {
    font-size: 5rem;
  }
}

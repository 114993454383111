.container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--main-color);
  border-radius: 2rem;
  top: 3.5rem;
  right: 20%;
  border: none;
  padding: 3rem 30%;
  cursor: auto;
}

.loginBtn {
  background-color: #fff;
  color: #000;
  width: 20rem;
}

.loginBtn:hover {
  color: #000;
}

.text {
  color: #000;
  font-size: 1.6rem;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

.linkCreateAccount {
  display: block;
  font-size: 1.6rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
}

.divider {
  background-color: #fff;
  margin: 1rem auto;
}

.button {
  background-color: #fff;
  width: 20rem;
  color: #000;
}

.button:hover {
  color: #000;
  box-shadow: #fff 0px 0px 19px 0px;
}

.marginButton {
  margin-bottom: 1rem;
}

.title {
  color: #000;
  font-size: 1.6rem;
  text-align: center;
  margin-bottom: 1.5rem;
}

@media screen and (max-width: 1380px) {
  .container {
    display: none;
  }
}

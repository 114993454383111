.container {
  max-width: 100%;
}

.image {
  border-radius: 2rem;
  object-fit: cover;
  margin-bottom: 0.5rem;
}

.productName {
  margin: 0;
  color: black;
  font-size: 1.6rem;
  font-weight: 800;
  margin-bottom: 0.85rem;
}

.productNameHover {
  color: var(--main-color);
  font-size: 2rem;
  font-weight: 700;
}

.currentPrice {
  margin: 0;
  color: var(--main-color);
  font-weight: 800;
  font-size: 2.4rem;
}

.previousPrice {
  margin: 0;
  color: #000;
  font-weight: 600;
  font-size: 1.4rem;
}

s {
  color: #000;
}

.button {
  width: 100%;
  height: 4rem;
  margin-top: 1.75rem;
  text-align: center;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  height: 10rem;
}

@media screen and (max-width: 767px) {
  .container {
    margin-bottom: 1rem;
  }

  .image {
    max-width: 100%;
  }

  .infoContainer {
    height: auto;
  }
}

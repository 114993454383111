.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10rem 7.5%;
  background-image: url("../../assets/images/application-instruction-section-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.sectionDivider {
  width: 75%;
  margin-bottom: 2.5rem;
}

.title {
  color: var(--main-color);
  font-size: 6rem;
  font-weight: bold;
  margin-bottom: 2.5rem;
  text-align: center;
}

.stepContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  width: 100%;
}

.step {
  background-color: var(--main-opacity-color);
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 500ms;
  box-shadow: 0px 0px 0px 0px var(--main-color);
}

.stepHover {
  transition: 500ms;
  box-shadow: 0px 0px 2rem 0px var(--main-color);
}

.stepImage {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 500ms;
  transform: rotate(0deg) scale(1);
}

.stepImageHover {
  transition: 500ms;
  transform: rotate(10deg) scale(1.15);
}

.stepTitle {
  color: #fff;
  font-size: 1.6rem;
  font-weight: bold;
  text-align: center;
}

.stepText {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 2rem;
}

@media screen and (max-width: 1380px) {
  .container {
    background-repeat: repeat-y;
    background-size: contain;
    background-attachment: unset;
    background-position: center;
    background-image: url("../../assets/images/application-instruction-section-mobile-bg.jpg");
  }
}
@media screen and (max-width: 767px) {
  .container {
    padding: 5rem 7.5%;
  }

  .title {
    font-size: 3.2rem;
    line-height: 1.1;
  }

  .stepContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .step {
    width: 100%;
  }

  .width {
    width: 100%;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1380px) and (-webkit-min-device-pixel-ratio: 1) {
  .container {
    background-image: url("../../assets/images/application-instruction-section-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: unset;
  }
}

.container {
  background-color: #000;
  margin: 0;
  padding: 1% 6% 1% 6%;
  box-sizing: border-box;
}

.content {
  display: flex;
  justify-content: space-between;
  margin: auto;
  align-items: center;
  max-width: 160rem;
}

.paymentMethodsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
}

.paymentMethodsText {
  font-size: 1.6rem;
  color: #848484;
}

.linksContainer {
  display: flex;
  align-items: center;
}

.copyrightText {
  color: #848484;
  font-size: 1.6rem;
  font-weight: 400;
  text-align: center;
}

.link {
  text-decoration: none;
  color: #848484;
  font-weight: 400;
  font-size: 1.6rem;
}

.blik {
  width: 5rem;
}

.twistotMethodImg {
  width: 2.5rem;
}

@media screen and (max-width: 1380px) {
  .content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .copyrightText {
    font-size: 1.2rem;
  }

  .link {
    font-size: 1.2rem;
  }

  .paymentMethodsContainer {
    margin-bottom: 1rem;
  }

  .paymentMethodsText {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 768px) {
  .paymentMethodsContainer {
    display: block;
    margin-top: 1rem;
  }

  .paymentMethodsText {
    margin-right: 0.5rem;
    text-align: center;
    margin-bottom: 0.5rem;
  }

  .twistotMethodImg {
    width: 2rem;
  }

  .paymentMethodImg {
    width: 6rem;
    margin-right: 0.5rem;
  }

  .creditCardMethodImg {
    width: 12rem;
    margin-right: 0.5rem;
  }
}

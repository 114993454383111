.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 8.2rem auto;
}

.title {
  font-size: 6rem;
  color: var(--main-color);
  margin-bottom: 2rem;
  text-align: center;
}

.info {
  font-size: 1.6rem;
  color: #000;
  margin-bottom: 2rem;
  text-align: center;
  width: 70%;
}

.button {
  background-color: red;
}

.error {
  display: block;
  color: red;
  font-size: 1.4rem;
  align-self: flex-start;
  align-self: center;
  text-align: center;
}

@media screen and (max-width: 1380px) {
  .button {
    width: 25rem;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0 1rem;
  }
  .button {
    width: 25rem;
  }
  .title {
    font-size: 4rem;
  }

  .info {
    font-size: 2rem;
  }
}

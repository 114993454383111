.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  font-size: 7.8rem;
  color: var(--main-color);
  font-weight: bold;
  line-height: 1.25;
  text-align: center;
}

.musclesDesc {
  font-size: 2rem;
  color: #000;
  font-weight: bold;
  margin-bottom: 1rem;
  margin-right: 5%;
  align-self: flex-end;
  text-align: center;
}

.titleDivider {
  width: 40%;
  margin-bottom: 2rem;
  align-self: flex-end;
}
.step {
  color: #000;
  font-size: 1.6rem;
  margin-bottom: 1.6rem;
  padding: 1rem;
}

.excerciseList {
  counter-reset: orderedlist;
  list-style-type: none;
}

.excerciseLists li::before {
  counter-increment: orderedlist;
  content: counter(orderedlist);
  font-size: 2.7rem;
  text-align: center;
  color: var(--main-color);
  font-weight: bold;
  padding-right: 1rem;
}

@media screen and (max-width: 1380px) {
  .container {
    padding: 0;
  }

  .title {
    font-size: 6rem;
  }
}

.container {
  display: block;
  box-sizing: border-box;
}

.question {
  font-size: 2rem;
  margin: 0;
  font-weight: bold;
  color: #000000;
  margin-bottom: 2rem;
}

.action {
  font-size: 3.6rem;
  font-weight: bold;
  color: #000;
  margin: 0;
  margin-bottom: 1rem;
}

.inputEmail {
  position: relative;
  margin-left: auto;
  min-width: 100%;
}

.input {
  box-sizing: border-box;
  border: none;
  border-radius: 3rem;
  padding: 2rem 4rem 2rem 4rem;
  width: 100%;
  color: #444;
  font-size: 1.6rem;
}

.input:focus-visible {
  outline: none;
}

.button {
  position: absolute;
  border: none;
  min-width: 29%;
  min-height: 70%;
  background-color: var(--main-color);
  color: #fff;
  border-radius: 2.4rem;
  box-shadow: 0 0.35rem 2.5rem #f42c3740;
  z-index: 2;
  overflow: hidden;
  top: 15%;
  right: 1rem;
  font-size: 1.6rem;
  cursor: pointer;
}

.error {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: red;
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 1rem;
  width: 100%;
}

.success {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: green;
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 1rem;
  width: 100%;
}

@media screen and (max-width: 1380px) {
  .button {
    font-size: 1.2rem;
    width: 35%;
    height: 2rem;
  }

  .input {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 767px) {
  .question {
    margin-bottom: 1rem;
  }

  .input {
    padding: 2rem 2rem 2rem 2rem;
  }

  .action {
    font-size: 3rem;
  }
}

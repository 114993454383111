.textLink {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: var(--nav-link-on-color);
  text-decoration: none;
  font-size: 1.6rem;
  font-weight: 500;
}

.textLinkActive {
  display: flex;
  align-items: center;
  position: relative;
  color: var(--nav-link-on-color);
  text-decoration: none;
  font-size: 1.6rem;
  font-weight: 600;
  color: #000;
}

.linkDropdown {
  position: relative;
  text-align: center;
}

.arrowDown {
  max-width: 0.9rem;
  margin-left: 5px;
}

.plusButton {
  display: none;
}

.dropdown {
  position: absolute;
}

.symbol {
  display: none;
}

@media screen and (max-width: 1380px) {
  .textLink {
    display: inline;
    color: black;
    text-transform: uppercase;
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 6rem;
  }

  .textLinkActive {
    display: inline;
    color: black;
    text-transform: uppercase;
    font-size: 2.4rem;
    font-weight: 800;
    line-height: 6rem;
  }

  .arrowDown {
    display: none;
  }

  .plusButton {
    display: inline;
    padding-left: 1rem;
    font-size: 3.5rem;
    background-color: transparent;
    border: none;
  }

  .dropdown {
    position: relative;
  }

  .linkElement {
    display: flex;
    justify-content: center;
  }

  .dropdownExpand {
    animation: expand 500ms ease-in-out;
  }

  .dropdownHide {
    animation: hide 500ms ease-in-out;
  }

  @keyframes expand {
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
  }

  @keyframes hide {
    0% {
      transform: scaleY(1);
    }
    100% {
      transform: scaleY(0);
    }
  }

  .symbol {
    display: block;
    max-width: 2.5rem;
    padding-left: 0.5rem;
  }
}

@media screen and (max-width: 767px) {
  .textLink {
    color: black;
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 700;
    line-height: 5rem;
  }

  .textLinkActive {
    color: black;
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 800;
    line-height: 5rem;
  }
}

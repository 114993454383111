.container {
  display: block;
  box-sizing: border-box;
}

.scoialMediaIconsList {
  list-style: none;
  padding-left: 0;
  margin-top: 0;
}

.desc {
  font-size: 1.6rem;
  color: #000;
  font-weight: 400;
  margin-bottom: 2rem;
}

.img {
  margin-bottom: 2rem;
}

@media screen and (max-width: 1380px) {
  .img {
    width: 50%;
  }
}

@media screen and (max-width: 767px) {
  .container {
    width: 100%;
  }

  .img {
    width: 50%;
    margin-bottom: 2rem;
  }

  .desc {
    margin-bottom: 2rem;
  }
}

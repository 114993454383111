.container {
  display: flex;
  background-image: url("../../assets/images/inercise_feature_section_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding: 3rem 5%;
  min-height: 50vh;
  margin: auto;
  flex-direction: column;
  align-items: center;
}

.divider {
  width: 70%;
  margin-left: -35%;
}

.title {
  color: var(--main-color);
  text-align: center;
  font-size: 5.4rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.cartItemsContainer {
  display: grid;
  grid-template-columns: 5fr 1.5fr;
  gap: 3rem;
  width: 90%;
  align-items: flex-start;
}

@media screen and (max-width: 1380px) {
  .cartItemsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
  }

  .paymentContainer {
    width: 100%;
  }

  .button {
    height: 4rem;
  }

  .productDeatils {
    text-align: center;
  }
}

@media screen and (max-width: 769px) {
  .container {
    justify-content: center;
  }

  .divider {
    display: none;
  }

  .productInCartContainer {
    display: flex;
    flex-direction: column;
  }

  .paymentContainer {
    box-shadow: #84ccd1 0px 0px 19px 0px;
  }

  .counterContainer {
    width: 10rem;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1380px) and (-webkit-min-device-pixel-ratio: 1) {
  .container {
    margin-top: 8rem;
  }
}

.container {
  display: block;
}

.image {
  border-radius: 2rem;
  display: block;
  object-fit: fill;
  height: auto;
}

.image:hover {
  transition: 500ms;
  box-shadow: 0px 0px 4rem 0px var(--main-color);
}

.titleContainer {
  margin: 4% 0 0 0;
}

.title {
  margin: 0;
  color: #fff;
  font-size: 1.6rem;
  font-weight: 800;
  margin-bottom: 1rem;
}

.desc {
  color: #fff;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.2;
}

.subtitle {
  font-size: 1.6rem;
  font-weight: normal;
  margin: 0;
  color: #fff;
}

@media screen and (max-width: 1380px) {
  .title {
    font-size: 1.4rem;
    font-weight: 600;
  }

  .descContainer {
    margin: 0;
    margin: 4% 0 1.6rem 0;
    max-width: 100%;
  }

  .desc {
    font-weight: 300;
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767px) {
  .container {
    margin-bottom: 8%;
  }

  .title {
    margin: 0;
    color: #fff;
    font-weight: 800;
    margin-bottom: 1rem;
    font-size: 1.6rem;
  }

  .desc {
    margin: 0;
    color: #fff;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.5;
  }

  .imageContainer {
    justify-content: center;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 42rem);
}

.title {
  font-size: 6rem;
  color: var(--main-color);
  margin-bottom: 2rem;
  text-align: center;
}

.info {
  font-size: 3rem;
  color: #000;
  margin-bottom: 2rem;
  text-align: center;
}

@media screen and (max-width: 1380px) {
  .container {
    margin: 8rem 0 4rem 0;
  }

  .button {
    width: 25rem;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0 1rem;
  }
  .button {
    width: 25rem;
  }
  .title {
    font-size: 4rem;
  }

  .info {
    font-size: 2rem;
  }
}

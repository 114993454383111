.list {
  position: absolute;
  background-color: var(--nav-bg-submenu-off-color);
  border-radius: 1.5rem 1.5rem 1.5rem 1.5rem;
  width: 25rem;
  list-style: none;
  padding: 2rem;
  border: solid 1px rgba(255, 255, 255, 0.2);
  text-align: start;
  top: 2.5rem;
}

.element {
  text-decoration: none;
  border-radius: 1.5rem;
  padding: 1rem;
}

.list:first-child {
  padding-top: 1rem;
}

.list:last-child {
  padding-top: 1rem;
}

.link {
  color: #000;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: 400;
}

.list:hover {
  background-color: var(--main-color);
  transition: 500ms;
  box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.5);
}

.element:hover {
  background-color: #fff;
  transition: 500ms;
}

@media screen and (max-width: 1380px) {
  .list {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #fff;
    list-style: none;
    border-radius: 0;
    width: 100vw;
  }

  .element {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .link {
    color: #000;
    text-decoration: none;
    font-size: 2rem;
    font-weight: 700;
    line-height: 4.5rem;
  }

  .textLink {
    display: flex;
    justify-content: center;
    color: black;
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 700;
    line-height: 6rem;
    min-width: 100%;
  }

  .list:hover {
    background-color: #fff;
    transition: 0;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
  }

  .dropdownExpand {
    animation: expand 500ms ease-in-out;
  }

  .dropdownHide {
    animation: hides 500ms ease-in-out;
  }

  @keyframes expand {
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
  }

  @keyframes hides {
    0% {
      transform: scaleY(1);
    }
    100% {
      transform: scaleY(0);
    }
  }
}

@media screen and (max-width: 1380px) {
  .list {
    top: 0rem;
  }

  .element {
    padding: 0rem;
  }

  .link {
    color: #000;
    text-decoration: none;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 4.5rem;
  }

  .textLink {
    padding: 0;
    margin: 0;
    line-height: 0;
  }
}

.container {
  position: relative;
  display: flex;
  overflow: hidden;
  margin: 0;
  background-image: url("../../assets/images/home_products_section_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  z-index: 1000;
}

.overlay {
  position: absolute;
  background-color: #fff;
  height: 100%;
  width: 100%;
  top: 0;
  opacity: 0.4;
}

.content {
  display: block;
  margin: auto;
  z-index: 1;
  width: 85%;
  margin-bottom: 6rem;
}

.sectionDivider {
  position: absolute;
  z-index: 2;
  width: 60%;
  left: 7%;
}

.firstElement {
  margin-top: 5rem;
}

.titleContainer {
  margin-bottom: 5rem;
}

.h2 {
  font-size: 6.4rem;
  color: var(--main-color);
  margin: 0;
  line-height: 1;
}

.titleDivider {
  z-index: 2;
  width: 17%;
  margin-top: 1%;
  margin-left: 35%;
}

.secondElement {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 4rem;
}

.fadeInLeft {
  display: block;
  visibility: hidden;
}

.fadeInLeftDelay {
  display: block;
  visibility: hidden;
}

.fadeInLeftAnimation {
  animation: fadeInLeft 1.5s ease-out forwards;
}

.fadeInLeftAnimationDelay {
  animation: fadeInLeft 1.5s ease 1.25s forwards;
}

@keyframes fadeInLeft {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: none;
    visibility: visible;
  }
}

.fadeOpacityAnimation {
  animation: fadeOpacity 2s ease-in forwards;
}

@keyframes fadeOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@media screen and (max-width: 1380px) {
  .container {
    background-repeat: repeat-y;
    background-attachment: unset;
    background-position: center;
    background-image: url("../../assets/images/home_products_section_mobile_bg.jpg");
  }
  .h2 {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) {
  .secondElement {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .titleContainer {
    margin-bottom: 2rem;
  }

  .h2 {
    font-size: 3rem;
    color: var(--main-color);
    margin: 0;
    margin-bottom: 1rem;
    line-height: 1;
    text-align: center;
  }

  .titleDivider {
    width: 80%;
    margin-left: 5%;
  }
}

.container {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 3rem 15% 6rem 15%;
  margin: auto;
}

.crossFadingImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 100%;
  height: auto;
  object-fit: cover;
  animation: move 50s ease infinite, fade 30s ease-in-out infinite;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.8;
}

.firstDivider {
  position: absolute;
  width: 35%;
  top: 2rem;
  left: 0;
}

.secondDivider {
  position: relative;
  justify-self: flex-end;
  width: 25%;
}

.thirdDivider {
  position: relative;
  width: 15%;
  margin-left: -10%;
}

.headerContainer {
  display: grid;
  grid-template-columns: 2.5fr 1fr;
  align-items: center;
  margin-bottom: 4rem;
}

.textContainer {
  display: flex;
  flex-direction: column;
  z-index: 2;
  align-items: center;
  margin-bottom: 2rem;
}

.counterContainer {
  display: flex;
  flex-direction: column;
  z-index: 2;
  align-items: flex-end;
}

.counter {
  color: #fff;
  font-size: 11.2rem;
  font-weight: 700;
  line-height: 1;
}

.motto {
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  padding-left: 25rem;
  text-shadow: #000 1px 0px 10px;
}

.pageTitle {
  font-size: 7.8rem;
  font-weight: bold;
  color: var(--main-color);
  margin: 0;
  padding: 0;
  text-shadow: #000 1px 0px 10px;
  line-height: 1;
  margin-bottom: 2rem;
}

.firstImageDelay {
  animation-delay: 0s;
}

.secondImageDelay {
  animation-delay: 10s;
}

.desc {
  color: #fff;
  font-size: 1.8rem;
  text-align: justify;
}

@keyframes move {
  0% {
    transform-origin: center;
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  34% {
    opacity: 0;
  }
  55% {
    opacity: 0;
  }
  77% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.fadeInLeft {
  /* visibility: hidden; */
}

.fadeInLeftDelay {
  display: block;
  visibility: hidden;
  width: 100%;
  height: 3rem;
}

.fadeInLeftAnimation {
  animation: fadeInLeft 1s ease-out forwards;
}

.fadeInLeftAnimationDelay {
  animation: fadeInLeft 1s 500ms ease-out forwards;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
}

.counterContainer {
  display: flex;
  flex-direction: column;
  z-index: 2;
  align-items: flex-end;
}

.counter {
  color: #fff;
  font-size: 11.2rem;
  font-weight: 700;
  line-height: 1;
}

@media screen and (max-width: 1380px) {
  .container {
    padding: 0;
  }

  .headerContainer {
    display: flex;
    flex-direction: column;
    padding: 4rem 2rem;
  }

  .textContainer {
    width: 70%;
  }

  .motto {
    padding-left: 0;
    padding-bottom: 2rem;
  }

  .pageTitle {
    font-size: 6.4rem;
    text-align: center;
  }

  .desc {
    font-size: 1.6rem;
    text-align: center;
  }

  .counterContainer {
    display: flex;
    flex-direction: column;
    z-index: 2;
    align-items: center;
  }

  .counter {
    color: #fff;
    font-size: 7.8rem;
    font-weight: 700;
    line-height: 1;
  }

  .firstDivider {
    position: relative;
    margin-top: 10rem;
    margin: 10rem auto 0 auto;
  }

  .secondDivider {
    display: none;
  }

  .thirdDivider {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .container {
    justify-content: center;
  }

  .crossFadingImg {
    position: absolute;
    top: 0;
    left: 0;
    height: inherit;
    object-fit: fill;
    animation: move 50s ease infinite, fade 20s ease-in-out infinite;
  }
}

.container {
  position: relative;
  display: flex;
  overflow: hidden;
  margin: 0;
  background-image: url("../../assets/images/home_intro_section_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.content {
  display: flex;
  overflow: hidden;
  z-index: 1;
}

.overlay {
  position: absolute;
  background-color: black;
  height: 100%;
  width: 100%;
  opacity: 0.89;
  top: 0;
}

hr {
  border: none;
  margin: 0;
  height: 2px;
  background-color: var(--main-color);
}

.sectionDivider {
  position: absolute;
  z-index: 2;
  width: 60%;
}

.firstElement {
  overflow: hidden;
  display: block;
  margin: 0;
  min-width: 40%;
  background-image: url("../../assets/images/home_intro_section_left_column.png");
  background-position: center right;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 5;
}

.secondElement {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 2% 2%;
  max-width: 60%;
  width: auto;
}

.companyName {
  font-size: 2rem;
  color: #ffffff;
  padding-left: 20%;
}

.companyNew {
  font-size: 15rem;
  color: #ffffff;
  padding-left: 20%;
}

.companyMotto {
  font-size: 7.8rem;
  color: var(--main-color);
  margin: 0;
  text-align: center;
  line-height: 1;
  margin-bottom: 2%;
}

.titlesDivider {
  position: relative;
  width: 16%;
  margin: 0 85% 2% 0;
}

.desc {
  font-size: 1.6rem;
  color: #fff;
  padding: 0 10%;
  font-weight: 500;
  margin-bottom: 2rem;
}

.endParagprahDivider {
  position: relative;
  width: 16%;
  margin-left: 70%;
  margin-bottom: 2%;
}

.featureBlocksContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  box-sizing: border-box;
  column-gap: 2.5rem;
}

.fadeInRightAnimation {
  animation: fadeInRight 1s ease-out forwards;
}

.fadeInRight {
  visibility: hidden;
}

.fadeLeft {
  visibility: hidden;
}

.fadeLeftAnimation {
  animation: fadeLeft 1s ease-out forwards;
}

@keyframes fadeLeft {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: none;
    visibility: visible;
  }
}

@keyframes fadeInRight {
  0% {
    transform: translate3d(100%, 0, 0);
  }
  100% {
    visibility: visible;
    transform: none;
  }
}

@media screen and (max-width: 1380px) {
  .container {
    background-image: url("../../assets/images/home_intro_section_bg_mobile.jpg");
    background-attachment: unset;
    background-repeat: repeat-y;
    background-size: contain;
  }

  .featureBlocksContainer {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }

  .secondElement {
    margin: 4rem 1%;
    max-width: 100%;
    width: auto;
  }

  .secondElement h3 {
    margin-bottom: 0;
  }

  .secondElement h2 {
    font-size: 4.5rem;
    text-align: center;
  }

  .titlesDivider {
    position: relative;
    width: 16%;
    margin: 7% 85% 0 0;
  }

  .desc {
    padding: 2% 10% 0 10%;
    font-weight: 400;
  }

  .endParagprahDivider {
    width: 20%;
    margin-top: 5%;
  }
}

@media screen and (max-width: 767px) {
  .overlay {
    opacity: 0.85;
  }

  .content {
    padding-top: 4rem;
  }

  .firstElement {
    display: none;
  }

  .featureBlocksContainer {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }

  .companyName {
    padding-top: 2r1m;
    padding-left: 50%;
    padding-bottom: 0;
  }

  .companyMotto {
    font-size: 2rem;
  }

  .titlesDivider {
    position: relative;
    width: 16%;
    margin: 0;
  }

  .endParagprahDivider {
    width: 20%;
    margin-top: 5%;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1380px) and (-webkit-min-device-pixel-ratio: 1) {
  .container {
    background-image: url("../../assets/images/home_intro_section_bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: unset;
    background-position: center;
  }
}

.container {
  position: relative;
  display: flex;
  width: 100%;
  margin-left: 0.5rem;
}

.currencyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.currencyIcon {
  width: 70%;
  border: 1px solid var(--main-color);
  border-radius: 2rem;
}

.currencyInfo {
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
}

.curencciesDropdownContainer {
  position: absolute;
  background-color: var(--main-color);
  top: 4rem;
  right: 0;
  padding: 1.5rem 3rem;
  border-radius: 2rem;
}

.currencies {
  position: relative;
  width: 100%;
}

.currencyLabel {
  font-size: 1.6rem;
  font-weight: 700;
  padding: 1rem 2rem 1rem 2rem;
}

.currencyLabel:hover {
  background-color: white;
  border-radius: 2rem;
}

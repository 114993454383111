.container {
  position: relative;
  display: flex;
  margin: 0;
  background-image: url("../../assets/images/home_benefits_section_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.content {
  display: flex;
  max-width: 100%;
  width: auto;
}

.overlay {
  position: absolute;
  background-color: var(--main-opacity-color);
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 0;
}

.firstElement {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 40%;
  z-index: 1;
  margin: 5rem 0 5rem 10rem;
}

.secondElement {
  display: flex;
  z-index: 100;
  align-items: center;
}

.innerdisk {
  display: block;
  width: 135%;
  margin-top: -5%;
  margin-bottom: -5%;
  transform: rotate(-175deg);
  transition: transform 3s ease-in-out;
}

.innerdisk:hover {
  transform: rotate(0deg);
}

.columnTextContainer {
  margin-left: 5%;
}

.question {
  font-size: 2rem;
  color: #ffffff;
  margin: 0;
  line-height: 1;
  font-weight: 700;
}

.slogan {
  font-size: 7.8rem;
  color: var(--main-color);
  line-height: 1;
  margin-bottom: 2rem;
}

.titlesDivider {
  width: 20%;
  margin-left: 15%;
  margin-bottom: 2rem;
}

.desc {
  font-size: 1.6rem;
  color: #ffffff;
  text-align: justify;
  line-height: 1.5;
  margin: 0% 35% 2.5rem 0%;
  padding: 0;
}

.endParagprahDivider {
  width: 85%;
  margin-left: -4%;
  margin-bottom: 3rem;
  margin-top: 1rem;
}

.buttonContainer {
  max-width: 100%;
  padding-left: 50%;
}

.sectionDivider {
  z-index: 2;
  width: 58%;
  margin-left: 2.55%;
}

.fadeInLeftSectionDivider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.fadeInLeft {
  width: 100%;
  visibility: hidden;
}

.fadeInLeftAnimation {
  animation: fadeInLeft 1s linear forwards;
}

.fadeInLeftDelay {
  visibility: hidden;
}

.fadeInLeftAnimationDelay {
  animation: fadeInLeft 250ms linear 750ms forwards;
}

@keyframes fadeInLeft {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: none;
    visibility: visible;
  }
}

.fadeUp {
  width: 100%;
  visibility: hidden;
}

.fadeUpAnimation {
  animation: fadeUp 1s ease-in forwards;
}

@keyframes fadeUp {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  100% {
    transform: none;
    visibility: visible;
  }
}

@media screen and (max-width: 1440px) {
  .innerdisk {
    width: 165%;
    transform: rotate(-175deg);
    transition: transform 3s ease-in-out;
  }
}

@media screen and (max-width: 1380px) {
  .container {
    background-position: center;
    background-image: url("../../assets/images/home_benefits_section_bg_mobile.jpg");
    background-repeat: repeat-y;
    background-attachment: unset;
  }
  .content {
    flex-direction: column;
  }

  .firstElement {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 3rem 4rem 2rem 4rem;
    max-width: 100%;
    z-index: 100;
  }

  .fadeInLeft {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sectionDivider {
    position: absolute;
    z-index: 2;
    width: 95%;
    margin-left: 2.55%;
  }

  .slogan {
    font-size: 6rem;
  }

  .break {
    display: none;
  }

  .fadeUp {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .innerdisk {
    display: block;
    margin-top: auto;
    width: 100%;
    margin: 0;
    transform: rotate(0deg);
  }

  .innerdisk:hover {
    transform: rotate(0deg);
  }

  .titlesDivider {
    position: relative;
    width: 80%;
    margin-left: 0;
    align-self: flex-end;
  }

  .fadeInLeftDelay {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .desc {
    margin: 3rem 10% 3rem 10%;
    text-align: center;
  }

  .endParagprahDivider {
    position: relative;
    width: 65%;
    margin: 0;
    margin-bottom: 20px;
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
  }
}

@media screen and (max-width: 767px) {
  .overlay {
    opacity: 0.85;
  }

  .content {
    margin-bottom: 0rem;
  }

  .sectionDivider {
    position: absolute;
    z-index: 2;
    width: 95%;
    margin-left: 2.55%;
  }

  .slogan {
    text-align: center;
  }

  .firstElement {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 3rem 4rem 2rem 4rem;
    max-width: 100%;
    z-index: 100;
  }

  .desc {
    margin-top: 0;
  }
  .endParagprahDivider {
    position: relative;
    width: 65%;
    margin: 0;
    margin-bottom: 20px;
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
    min-width: 100%;
    margin: 0;
  }
}

.container {
  display: flex;
  width: 4rem;
  height: 4rem;
  border-radius: 4rem;
  border: 0.2rem solid var(--main-color);
  justify-content: center;
  align-items: center;
}

.img {
  object-fit: cover;
  width: 80%;
  height: 80%;
  border-radius: 2rem;
}

.container {
  background-image: url("../../assets/images/excerciseAtlas-application-section-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.overlay {
  position: relative;
  background-color: transparent;
  background-image: linear-gradient(50deg, #ffffff 0%, #000000de 100%);
}

.contentContainer {
  padding: 10rem 8% 0rem 8%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.sectionDivider {
  position: absolute;
  width: 50%;
  top: 5rem;
  left: 25%;
}

.appImg {
  width: 80%;
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.title {
  color: var(--main-color);
  font-size: 7rem;
  text-align: center;
  line-height: 1.25;
  margin-bottom: 3rem;
}

.titleDivider {
  width: 45%;
  align-self: flex-end;
  margin-bottom: 3rem;
}

.desc {
  color: #000;
  font-size: 1.6rem;
  text-align: justify;
  margin-bottom: 4rem;
}

.button {
  align-self: flex-end;
  margin-bottom: 3rem;
}

@media screen and (max-width: 1380px) {
  .contentContainer {
    padding-bottom: 5rem;
    gap: 2rem;
  }

  .title {
    color: var(--main-color);
    font-size: 5rem;
    text-align: center;
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 0;
  }

  .contentContainer {
    padding-bottom: 2.5rem;
    display: block;
  }

  .title {
    color: var(--main-color);
    font-size: 5.4rem;
    text-align: center;
    line-height: 1;
    margin-bottom: 3rem;
  }

  .desc {
    text-align: center;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 35%;
  height: calc(100vh - 42.3rem);
}

.title {
  font-size: 4.5rem;
  color: #000;
  margin-bottom: 3rem;
  text-align: center;
}

.form {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  color: #000;
  width: 100%;
}

.input {
  margin-bottom: 2rem;
  padding: 1rem 2rem;
  font-size: 1.8rem;
  border-radius: 2rem;
  border: 2px solid #000;
  border-color: var(--main-color);
}

.input:last-of-type {
  margin-bottom: 1rem;
  margin-top: 3rem;
}

.info {
  font-size: 1.6rem;
}

.error {
  color: red;
  font-size: 1.4rem;
}

.input::placeholder {
  font-size: 1.4rem;
}

.linkToRecovery {
  font-size: 1.2rem;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-color);
  color: #fff;
  font-size: 1.4rem;
  font-weight: 600;
  padding: 1rem 2rem;
  height: 4rem;
  cursor: pointer;
  border-radius: 2.5rem;
  text-decoration: none;
}

.button:active {
  animation: clicked 1s;
}

@keyframes clicked {
  0% {
    background-color: var(--main-color);
  }
  50% {
    background-color: white;
    color: var(--main-color);
  }
  100% {
    background-color: var(--main-color);
  }
}

.text {
  color: #000;
  font-size: 1.6rem;
  margin-bottom: 0.5rem;
}

.linkCreateAccount {
  font-size: 1.6rem;
  font-weight: 700;
  color: var(--main-color);
}

.button:hover {
  box-shadow: 0 0.35rem 2.5rem #f42c3740;
}

.addressLine {
  margin-bottom: 1rem;
}

.link {
  background-color: transparent;
  border: none;
  text-decoration: underline;
  align-self: flex-start;
  cursor: pointer;
  color: darkblue;
}

@media screen and (max-width: 1380px) {
  .container {
    width: 60%;
    margin: 10rem 0 6rem 0;
  }
}

@media screen and (max-width: 768px) {
  .container {
    min-height: calc(100vh - 42.3rem);
    height: auto;
  }

  .container {
    width: 80%;
    margin: 8rem 0 6rem 0;
  }
}

.container {
  position: relative;
  padding: 0 13rem 3rem 13rem;
  background-image: url("../../assets/images/contact_section_bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: calc(100vh - 42.3rem);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  background-image: linear-gradient(
    360deg,
    var(--main-color) 0%,
    #ffffff00 100%
  );
  opacity: 0.5;
  width: 100%;
  height: 100%;
}

.content {
  position: relative;
}

.title {
  color: var(--main-color);
  font-size: 7.8rem;
  font-weight: bold;
}

.titleDivider {
  display: block;
  width: 30%;
  margin-left: auto;
}

.socialIconsContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 5rem;
  margin-bottom: 4rem;
}

.socialMediaLink {
  display: flex;
  text-decoration: none;
  background-color: var(--main-color);
  color: #fff;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 5rem;
  border-radius: 2.5rem;
}

.socialMediaIconsDivider {
  width: 10%;
  margin-bottom: 4rem;
}

.commentForm {
  display: flex;
  max-width: 50%;
  width: auto;
  flex-direction: column;
}

.textForm {
  display: block;
  font-size: 1.8rem;
  font-weight: 500;
  background-color: #efefef;
  border: none;
  border-radius: 2.5rem;
  outline: 0;
}

.email {
  padding: 2rem 3rem 0 3rem;
}

.message {
  padding: 2rem 3rem 0 3rem;
  margin-top: 3rem;
  height: 15rem;
}

.submitBtn {
  display: block;
  background-color: var(--main-color);
  color: rgb(255, 255, 255);
  font-size: 1.6rem;
  font-weight: 500;
  text-transform: capitalize;
  border: none;
  border-radius: 2.5rem;
  padding: 1.6rem 7rem;
  max-width: 19rem;
  cursor: pointer;
}

.submitBtn:hover {
  color: #ffffff;
  box-shadow: #84ccd1 0px 0px 10px 5px;
}

.submitBtn:active {
  animation: clicked 350ms;
}

@keyframes clicked {
  0% {
    background-color: var(--main-color);
  }
  50% {
    background-color: white;
    color: var(--main-color);
  }
  100% {
    background-color: var(--main-color);
  }
}

.smIcon {
  width: 2.5rem;
  height: 2.5rem;
}

.error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  color: red;
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 0.5rem;
  background-color: var(--main-color);
  width: 40%;
  border-radius: 2rem;
}

.submitContainer {
  margin-top: 3rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.success {
  display: flex;
  justify-content: center;
  align-items: center;
  color: green;
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 0.5rem;
  background-color: var(--main-color);
  width: 40%;
  text-align: center;
  border-radius: 2rem;
  margin-right: auto;
  height: 4rem;
}

.fadeUp {
  visibility: hidden;
}

.fadeUpAnimation {
  animation: fadeUp 1s ease-out forwards;
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 50%, 0);
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
}

.fadeInLeftDelay {
  visibility: hidden;
}

.fadeInLeftAnimationDelay {
  animation: fadeInLeft 1s 500ms ease-out forwards;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  0% {
    opacity: 0.5;
    transform: translate3d(-50%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
}

@media screen and (max-width: 1380px) {
  .container {
    padding: 0rem 3rem 3rem 3rem;
  }

  .titleDivider {
    display: none;
  }

  .commentForm {
    max-width: 100%;
  }

  .socialMediaIconsDivider {
    width: 70%;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 7rem 3rem 3rem 3rem;
    background-position: center;
  }

  .title {
    font-size: 6rem;
  }

  .titleDivider {
    display: none;
  }

  .commentForm {
    max-width: 100%;
  }

  .socialMediaLink {
    width: 4rem;
    height: 4rem;
    border-radius: 2rem;
  }

  .socialMediaIconsDivider {
    width: 80%;
  }

  .textForm {
    font-size: 1.6rem;
  }
}

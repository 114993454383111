.container {
  position: relative;
  display: flex;
  width: 100%;
  margin-left: 0.5rem;
}

.flag {
  width: 100%;
  border: 1px solid var(--main-color);
  border-radius: 2rem;
}

.languageDropdownContainer {
  position: absolute;
  background-color: var(--main-color);
  top: 3.5rem;
  right: 0;
  padding: 1.5rem 2rem;
  border-radius: 2rem;
}

.additionalLanguages {
  position: relative;
  width: 100%;
}

.flagAdditionalLanguages {
  width: 4rem;
  height: 4rem;
}

.text {
  font-size: 1.6rem;
  font-family: "Montserrat";
}

.additionalLanguage {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 0.5rem 3rem;
}

.additionalLanguage:hover {
  background-color: white;
  border-radius: 2rem;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  background-image: url("../../assets/images/blog_feature_section_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding: 6rem 0;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  max-width: 140rem;
  width: 100%;
  padding: 0 10rem 0 10rem;
}

.button {
  color: #000;
  max-width: 15.8rem;
  height: 5.5rem;
  font-size: 1.2rem;
  font-weight: normal;
  text-transform: uppercase;
  flex-grow: 1;
  cursor: pointer;
}

.articlesContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5rem;
  margin: 4rem 15rem;
}

.articleBlock {
  color: #000;
}

.fadeOpacity {
  visibility: hidden;
}

.fadeOpacityAnimation {
  animation: fadeOpacity 750ms ease-in forwards;
}

@keyframes fadeOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

.fadeInLeftDelay {
  visibility: hidden;
}

.fadeInLeftAnimationDelay {
  animation: fadeInLeft 1s 500ms ease-out forwards;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  0% {
    opacity: 0.5;
    transform: translate3d(-50%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
}

@media screen and (max-width: 1380px) {
  .container {
    background-repeat: repeat-y;
    background-image: url("../../assets/images/blog_feature_section_bg.jpg");
    background-size: fill;
    background-attachment: unset;
    padding: 0rem 0;
  }

  .buttonContainer {
    display: none;
  }

  .articlesContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5rem;
    margin: 4rem 5%;
  }
}

@media screen and (max-width: 767px) {
  .articlesContainer {
    display: flex;
    flex-direction: column;
    gap: 0rem;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
}

.form {
  width: 100%;
}

.info {
  display: block;
  color: #000;
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.title {
  font-size: 5rem;
  color: #000;
  margin-bottom: 1rem;
  text-align: center;
}

.subtitle {
  font-size: 1.2rem;
  margin-bottom: 5rem;
  text-align: center;
}

.nameInputContainer {
  display: flex;
  gap: 2rem;
  margin-bottom: 4rem;
  justify-content: center;
}

.emailInputContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;
}

.emailInputContainer > * {
  margin-bottom: 1rem;
}

.info {
  font-size: 1.6rem;
}

.input {
  font-size: 1.8rem;
  padding-left: 1rem;
  border-radius: 2rem;
  border: 2px solid #000;
  border-color: var(--main-color);
  padding: 1rem 2rem;
  width: 100%;
}

.form > input {
  margin-bottom: 1rem;
}

.inputCheckbox {
  display: span;
  margin-right: 1rem;
}

.labelNewsletter {
  color: #000;
  font-size: 1.4rem;
}

.newsletterContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 3rem;
}

.inputNewsletter {
  width: auto;
  margin-right: 1rem;
}

.inputNewsletter:checked {
  color: var(--main-color);
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-color);
  color: #fff;
  font-size: 1.4rem;
  font-weight: 600;
  padding: 1rem 2rem;
  height: 4rem;
  cursor: pointer;
  border-radius: 2.5rem;
  text-decoration: none;
}

.button:hover {
  box-shadow: 0 0.35rem 2.5rem #f42c3740;
}

.button:active {
  animation: clicked 1s;
}

@keyframes clicked {
  0% {
    background-color: var(--main-color);
  }
  50% {
    background-color: white;
    color: var(--main-color);
  }
  100% {
    background-color: var(--main-color);
  }
}

.error {
  display: block;
  color: red;
  font-size: 1.4rem;
  padding-left: 1rem;
}

@media screen and (max-width: 1380px) {
  .container {
    width: 60%;
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 80%;
    margin: 8rem auto 4rem auto;
  }
}

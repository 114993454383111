@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", Sans-Serif;
}

:root {
  --main-color: #77c8d2;
  --main-opacity-color: #77c8d268;
  --bg-off-color: #77c8d2a1;
  --nav-link-on-color: #aaa;
  --nav-link-off-color: #848484;
  --nav-link-side-off-color: rgba(0, 0, 0, 0.8);
  --nav-bg-submenu-off-color: #77c8d2a1;

  --mobile-breakpoint: 767px;
  --tablet-breakpoint: 1380px;
}

html {
  display: block;
  font-size: 10px;
  font-weight: 500;
  line-height: 1.5;
  margin-top: 8rem;
  color: var(--nav-lin-on-color);
  -webkit-text-size-adjust: 100%;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1380px) and (-webkit-min-device-pixel-ratio: 1) {
  html {
    margin-top: 0rem;
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  html {
    margin-top: 4rem;
  }
}

@media screen and (max-width: 767px) {
  .containerMobile {
    position: fixed;
    overflow-y: hidden;
  }

  html {
    margin-top: 4rem;
  }

  @media not all and (min-resolution: 0.001dpcm) {
    html {
      margin-top: 0rem;
    }
  }
}

.container {
  display: block;
  padding: 2rem 10%;
  background-image: url("../../assets/images/privacy_policy_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.title {
  color: var(--main-color);
  font-size: 7.8rem;
  font-weight: bold;
  text-align: center;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.desc {
  color: #000;
  font-size: 1.6rem;
  list-style-position: inside;
}

.sectionDivider {
  margin-left: -15%;
  width: 60%;
}

.titleDivider {
  margin-bottom: 2rem;
}

li > ol {
  padding-left: 2.5rem;
}

@media screen and (max-width: 767px) {
  .title {
    text-align: center;
    font-size: 4rem;
  }

  .desc {
    font-size: 1.2rem;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 42.3rem);
}

.title {
  font-size: 5rem;
  color: #000;
  margin-bottom: 3rem;
}

.form {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  color: #000;
}

.input {
  margin-bottom: 1rem;
  padding: 1rem 2rem;
  font-size: 1.8rem;
  border-radius: 2rem;
  border: 2px solid #000;
  border-color: var(--main-color);
}

.info {
  font-size: 1.6rem;
  margin-bottom: 0.5rem;
}

.error {
  color: red;
  font-size: 1.4rem;
  margin-bottom: 3rem;
}

.input::placeholder {
  font-size: 1.4rem;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-color);
  color: #fff;
  font-size: 1.4rem;
  font-weight: 600;
  padding: 1rem 2rem;
  height: 4rem;
  cursor: pointer;
  border-radius: 2.5rem;
  text-decoration: none;
  border: none;
}

.button:hover {
  box-shadow: 0 0.35rem 2.5rem #f42c3740;
}

@media screen and (max-width: 768px) {
  .container {
    margin: 3rem 2rem;
  }

  .form {
    width: 100%;
  }

  .title {
    text-align: center;
  }

  .info {
    text-align: center;
    margin-bottom: 2rem;
  }
}

.container {
  display: flex;
  flex-direction: column;
  background-image: url("../../assets/images/inercise_feature_section_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding: 3rem 0;
  width: 100%;
}

.featuresContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.deliveryText {
  color: #000;
  font-size: 1.6rem;
  font-weight: 700;
}

.form {
  display: flex;
  flex-direction: column;
  border-radius: 2rem;
  background-color: #fff;
  padding: 4rem;
}

.formContainer {
  display: flex;
  justify-content: space-between;
}

.subtitle {
  font-size: 1.6rem;
  color: #000;
  margin-bottom: 1rem;
}

.radio {
  background-color: #000;
  margin-right: 1rem;
}

.radioLablel {
  color: #000;
  font-size: 1.6rem;
}

.radioButtonContainer {
  display: flex;
  align-items: center;
}

.addressContainer {
  width: 50%;
  margin-bottom: 3rem;
}

.divider {
  width: 80%;
  margin-left: -65%;
}

.title {
  color: var(--main-color);
  text-align: center;
  font-size: 5.4rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.checkboxContainer {
  border: none;
  display: flex;
  justify-content: flex-start;
  align-self: flex-start;
  margin: 1rem;
}

.checkboxLabel {
  color: #000;
  font-size: 1.4rem;
  margin-left: 1rem;
}

@media screen and (max-width: 1380px) {
  .paymentContainer {
    position: relative;
    left: 0;
    width: 80%;
    background-color: #fff;
    border-radius: 2rem;
    padding: 2rem;
    margin-right: 0;
    margin-top: 2rem;
  }

  .addressContainer {
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
  .addressContainer {
    width: 90%;
  }

  .title {
    font-size: 4rem;
  }

  .paymentContainer {
    box-shadow: #84ccd1 0px 0px 19px 0px;
  }

  .form {
    padding: 2rem;
    box-shadow: #84ccd1 0px 0px 19px 0px;
  }

  .formContainer {
    flex-direction: column;
    gap: 2rem;
  }

  .radioLablel .deliveryText {
    font-size: 1.4rem;
  }

  .button {
    width: 90%;
  }

  .error {
    text-align: center;
  }
}

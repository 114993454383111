.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  border-radius: 2rem;
  padding: 2rem;
}

.valueContainer {
  display: flex;
  justify-content: space-between;
}

.closeImage {
  width: 2rem;
  cursor: pointer;
}

.cartPriceBeforeDiscount {
  color: #000;
  font-size: 1.2rem;
  font-weight: 500;
}

.cartValue {
  color: #000;
  font-size: 1.6rem;
  text-align: end;
}

.button {
  margin-top: 1rem;
  width: 100%;
  text-align: center;
}

.error {
  display: block;
  color: red;
  font-size: 1.4rem;
  margin-top: 1rem;
  text-align: center;
}

.info {
  text-align: center;
  font-size: 1.2rem;
  margin-top: 1rem;
  font-weight: 600;
}

.discountCode {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  width: 100%;
}

.discountCode div {
  display: flex;
  margin-top: 1rem;
  width: 100%;
}

.discountCode input {
  border: solid 0.2rem var(--main-color);
  border-radius: 2rem 0 0 2rem;
  padding-left: 2rem;
  width: 80%;
  padding: 1rem 2rem;
}

.discountCode button {
  text-align: center;
  width: 20%;
  border: none;
  background-color: var(--main-color);
  border-radius: 0 2rem 2rem 0;
  color: #fff;
  cursor: pointer;
  font-weight: 600;
}

.discountCode img {
  width: 20%;
  filter: invert(100%);
}

.discountCode button:hover {
  color: #ffffff;
  box-shadow: #84ccd1 0px 0px 10px 5px;
}

@media screen and (max-width: 1380px) {
  .discountCode img {
    width: 10%;
  }
}

@media screen and (max-width: 769px) {
  .container {
    box-shadow: #84ccd1 0px 0px 10px 5px;
  }

  .discountCode img {
    width: 20%;
  }
}

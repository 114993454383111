.container {
  display: block;
  text-align: center;
  border-radius: 2rem;
  margin: 2rem 0;
  box-sizing: border-box;
  padding: 1rem;
  line-height: 1.5;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 92%;
  max-width: 45rem;
  padding: 3rem 3rem;
}

.title {
  color: #fff;
  font-size: 1.6rem;
  font-weight: bold;
  margin: 0;
}

.text {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.5;
}

@media screen and (max-width: 1380px) {
  .container {
    max-width: 100%;
    margin: 2rem 2rem;
    min-height: auto;
  }
}

@media screen and (max-width: 767px) {
  .container {
    max-width: 100%;
    margin: 2rem 2rem;
    min-height: auto;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-color);
  color: #fff;
  font-size: 1.4rem;
  font-weight: 600;
  padding: 1rem 2rem;
  width: 25rem;
  height: 4rem;
  cursor: pointer;
  border-radius: 2.5rem;
  text-decoration: none;
}

.button:hover {
  color: #ffffff;
  box-shadow: #84ccd1 0px 0px 10px 5px;
}

.buttonDisabled {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-color);
  color: #fff;
  font-size: 1.4rem;
  font-weight: 600;
  padding: 1rem 2rem;
  width: 25rem;
  height: 4rem;
  border-radius: 2.5rem;
  text-decoration: none;
  opacity: 0.5;
  cursor: default;
}

.buttonChecked {
  background-color: var(--main-color);
  color: #ffffff;
  box-shadow: #84ccd1 0px 0px 10px 7.5px;
}

.button:active {
  animation: clicked 350ms;
}

@keyframes clicked {
  0% {
    background-color: var(--main-color);
  }
  50% {
    background-color: white;
    color: var(--main-color);
  }
  100% {
    background-color: var(--main-color);
  }
}

@media screen and (max-width: 1380px) {
  .button {
    height: 5rem;
    width: 20rem;
    padding: 0 3%;
  }
}

@media screen and (max-width: 767px) {
  .button {
    height: 5rem;
    width: 25rem;
  }
}

.form {
  display: flex;
  flex-direction: column;
  border-radius: 2rem;
  background-color: #fff;
  padding: 4rem;
}

.subtitle {
  font-size: 1.6rem;
  color: #000;
  margin-bottom: 1rem;
}

.groupInputs {
  display: flex;
  gap: 2rem;
}

.countryInputContainer {
  width: 100%;
}

.input {
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  border-radius: 2rem;
  border: 2px solid #000;
  border-color: var(--main-color);
  padding: 1rem 2rem;
  margin-bottom: 1rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.error {
  display: block;
  color: red;
  font-size: 1.4rem;
  align-self: flex-start;
}

.label {
  color: #000;
  font-size: 1.4rem;
}

option {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
}

option {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

.phoneNumberContainer {
  display: block;
  width: 100%;
}

.phoneNumber {
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 1rem;
}

.postalCode {
  width: 50%;
}

@media screen and (max-width: 768px) {
  .form {
    padding: 1.5rem;
    box-shadow: #84ccd1 0px 0px 19px 0px;
  }

  .groupInputs {
    display: block;
  }

  .error {
    text-align: center;
  }
}

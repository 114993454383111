.container {
  display: flex;
  justify-content: center;
  background-color: var(--main-color);
}

.content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2.5fr;
  width: 160rem;
  gap: 5rem;
  margin: 5rem 2%;
}

.opacityFirsta {
  visibility: hidden;
}

.opacitySecond {
  visibility: hidden;
}

.opacityThird {
  visibility: hidden;
}

.opacityFourth {
  visibility: hidden;
}

.opacityFirstAnimation {
  animation: opacity 500ms ease-out forwards;
}

.opacitySecondAnimation {
  animation: opacity 500ms 250ms ease-out forwards;
}

.opacityThirdAnimation {
  animation: opacity 500ms 500ms ease-out forwards;
}

.opacityFourthAnimation {
  animation: opacity 500ms 750ms ease-out forwards;
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@media screen and (max-width: 1380px) {
  .content {
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
    row-gap: 6rem;
    margin: 8rem 5%;
  }
}

@media screen and (max-width: 767px) {
  .content {
    display: flex;
    flex-direction: column;
    margin: 5rem 5%;
    gap: 6rem;
  }
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
  z-index: 10000;
  height: 8rem;
}

.container {
  display: flex;
  max-width: 160rem;
  flex-grow: 1;
  padding: 0 2%;
  gap: 2rem;
}

.imgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5rem;
}

.logo {
  display: block;
  visibility: hidden;
}

.fade {
  display: block;
}

.fadeAnimation {
  animation: fade 500ms ease-in forwards;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@media screen and (max-width: 1380px) {
  .container {
    padding: 0 4%;
  }
}

@media screen and (max-width: 767px) {
  .logo {
    max-width: 12rem;
  }
}

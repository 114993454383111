.container {
  display: inline-grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  justify-content: center;
  align-items: center;
  margin-bottom: 6rem;
}

.title {
  font-size: 3rem;
  color: var(--main-color);
  font-weight: 700;
  margin-bottom: 1rem;
  text-align: center;
}

.description {
  font-size: 1.6rem;
  color: #fff;
  text-align: justify;
}

.imageContainerEnd {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.imageContainerStart {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.image {
  border-radius: 2.2rem;
  width: 95%;
  align-items: flex-end;
  transition: 250ms ease-out;
}

.image:hover {
  box-shadow: #84ccd1 0px 0px 4rem 0px;
}

.divider {
  width: 60%;
  margin: 0 auto 1rem auto;
}

@media screen and (max-width: 1380px) {
  .description {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767px) {
  .container {
    display: flex;
    flex-direction: column;
  }

  .image {
    margin-bottom: 2rem;
  }

  .imageContainerStart {
    display: flex;
    justify-content: center;
  }
}

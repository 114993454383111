.container {
  display: flex;
  width: 100%;
  gap: 1rem;
  border: 2px solid var(--main-color);
  border-radius: 2rem;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.counterIcon {
  width: 1.5rem;
  cursor: pointer;
}

.counter {
  color: #000;
  font-size: 1.6rem;
  margin: auto 0.5rem;
}

@media screen and (max-width: 769px) {
  .container {
    width: 50%;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  min-height: calc(100vh);
}

@media screen and (max-width: 1380px) {
  .container {
    padding: 0;
    padding-top: 8rem;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 0;
    padding-top: 4rem;
  }
}

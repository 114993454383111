.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.input {
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  border-radius: 2rem;
  border: 2px solid #000;
  border-color: var(--main-color);
  padding: 1rem 2rem;
  margin-bottom: 1rem;
  width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.label {
  color: #000;
  font-size: 1.4rem;
}

.phoneContainer {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.countryCode {
  font-size: 1.4rem;
  margin-right: 0.5rem;
}

.inputPhoneNumber {
  margin-bottom: 0rem;
}

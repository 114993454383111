.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}

.textLinkList {
  display: flex;
  list-style: none;
  gap: 4rem;
}

.textLink {
  display: flex;
  align-items: center;
  position: relative;
  color: var(--nav-link-on-color);
  text-decoration: none;
  border: none;
}

.textLink:active {
  border: none;
}

.iconLinkList {
  position: relative;
  display: flex;
  list-style: none;
  gap: 1rem;
}

.iconLink {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  cursor: pointer;
  width: 4rem;
  height: 4rem;
}

.cart {
  position: absolute;
  top: 0.5rem;
  right: 0.55rem;
  border-radius: 0.25rem;
  background-color: var(--main-color);
  width: 18px;
  height: 18px;
}

.itemsCounter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  color: #fff;
  font-size: 1.4rem;
}

.hamburger {
  display: none;
}

.expandedNavbarCloseMobileButton {
  display: none;
}

.mobileSectionDivider {
  display: none;
}

.loginContainer {
  display: none;
}

.mobileMenuContainer {
  display: none;
}

@media screen and (max-width: 1380px) {
  .nav {
    justify-content: flex-end;
  }

  .textLinkList {
    display: none;
  }

  .textLink {
    width: 100%;
    justify-content: center;
  }

  .hamburger {
    display: inline;
    border: none;
    background-color: #fff;
  }

  .hamburger img {
    width: 2rem;
    height: 2rem;
  }

  .mobileMenuContainer[open] {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-image: url("/src/assets/images/header_expanded_nav_bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 100000;
    animation: animation 500ms ease-in-out;
    min-height: 100vh;
    max-width: 100vw;
    width: 100%;
    top: 0;
    left: 0;
    border: none;
    overflow-y: hidden;
  }

  @keyframes animation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .cancelIcon {
    width: 4rem;
    filter: invert(100%);
    margin: 2rem auto;
  }

  .mobileSectionDivider {
    display: block;
    position: relative;
    background-color: #fff;
    width: 80%;
    height: 2px;
    margin-bottom: 2rem;
  }

  .loginContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .loginBtn {
    background-color: #fff;
    color: #000;
    width: 55%;
    font-size: 2rem;
  }

  .text {
    color: #000;
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .buttonCreateAccount {
    background-color: #fff;
    color: #000;
    width: 45%;
    font-size: 1.6rem;
  }

  .button {
    background-color: #fff;
    width: 20rem;
    color: #000;
  }

  .marginButton {
    margin-bottom: 1rem;
  }

  .title {
    color: #000;
    font-size: 1.6rem;
    text-align: center;
    margin-bottom: 1.5rem;
  }

  .iconLinkList {
    gap: 0rem;
  }

  .divider {
    margin: 1rem auto;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15rem auto;
}

.title {
  font-size: 5rem;
  color: #000;
  margin-bottom: 3rem;
  text-align: center;
}

.button {
  text-decoration: none;
  border: none;
  background-color: var(--main-color);
  color: #fff;
  border-radius: 2.4rem;
  z-index: 2;
  overflow: hidden;
  top: 15%;
  right: 1rem;
  font-size: 1.6rem;
  cursor: pointer;
  padding: 1rem 2rem;
}

.button:hover {
  box-shadow: 0 0.35rem 2.5rem #f42c3740;
}

.serialNumber {
  font-size: 1.6rem;
  margin-bottom: 3rem;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .container {
    margin: 5rem 1rem;
  }
}

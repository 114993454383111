.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../../assets/images/blog_feature_section_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.sectionDivider {
  position: absolute;
  width: 80%;
  top: 0;
  left: 0;
}

.buttonsDivider {
  width: 100%;
  background-color: #fff;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  padding: 4rem 20%;

  gap: 2rem;
}

.button {
  max-width: 17rem;
  height: 5.5rem;
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #84ccd1;
  text-shadow: 0 2px 0 #66c0c6;
  color: white;
}

.shoppingContainer {
  display: grid;
  grid-template-columns: 2fr 5fr;
  width: 100%;
  padding: 4rem 7.5%;
}

.filterContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem 5rem 0 0;
}

.title {
  color: #000;
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.filterDivider {
  margin-bottom: 4rem;
  background-color: lightgray;
  height: 0.05rem;
}

.productBlock {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 3rem;
  row-gap: 6rem;
}

.noProductsText {
  text-align: center;
  color: #000;
  font-size: 3rem;
  align-self: center;
  grid-column: 1 / 5;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

.fadeIn {
  visibility: hidden;
}

.fadeInAnimation {
  animation: fade 1s ease-out forwards;
}

@media screen and (max-width: 1380px) {
  .container {
    background-image: url("../../assets/images/blog_feature_section_bg.jpg");
    background-repeat: repeat-y;
    background-size: 100%;
    background-attachment: unset;
    padding-bottom: 0rem;
  }

  .productBlock {
    grid-template-columns: 1fr 1fr;
  }

  .buttonContainer {
    padding: 4rem 10%;
  }
}

@media screen and (max-width: 768px) {
  .productBlock {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .buttonContainer {
    display: none;
  }

  .shoppingContainer {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    padding: 2rem 7.5%;
  }

  .filterContainer {
    padding: 5%;
    margin-bottom: 2rem;
  }

  .bannerImg {
    display: none;
  }
}

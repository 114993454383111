.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(../../assets/images/inercise_feature_section_bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.content {
  display: inline-grid;
  grid-template-columns: minmax(0, 1.3fr) minmax(0, 1fr);
  justify-items: center;
  align-items: center;
}

.inerciseImg {
  display: block;
  width: 100%;
  height: auto;
  margin-top: -15%;
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0% 15% 5% 0;
}

.subtitle {
  font-size: 1.9rem;
  color: #000;
  font-weight: 700;
  padding-left: 25%;
  margin-bottom: -1rem;
}

.sectionDivider {
  width: 45%;
  margin-top: 2rem;
  align-self: flex-start;
  margin-left: 10%;
}

.title {
  font-size: 7.8rem;
  color: var(--main-color);
  font-weight: 700;
  line-height: 1.3;
}

.titleDivider {
  display: block;
  width: 40%;
  margin-bottom: 2rem;
  margin-left: -5%;
  align-self: flex-start;
}

.text {
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #000;
  text-align: justify;
}

.endDivider {
  display: block;
  width: 20%;
  margin-left: 90%;
  margin-top: 1rem;
}

.text:last-of-type {
  margin-bottom: 0;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1380px) and (-webkit-min-device-pixel-ratio: 1) {
  .container {
    margin-top: 8rem;
  }
}

@media screen and (max-width: 1380px) {
  .container {
    background-position: center;
    background-size: cover;
    background-attachment: unset;
  }

  .sectionDivider {
    display: none;
  }

  .text {
    font-size: 1.4rem;
  }

  .textContainer {
    margin: 3rem 15% 5rem 0;
  }

  .endDivider {
    display: block;
    width: 20%;
    margin-left: 90%;
  }
}

@media screen and (max-width: 767px) {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
  }

  .textContainer {
    margin: 0;
  }

  .text {
    padding-left: 5%;
    padding-right: 5%;
  }

  .subtitle {
    padding-left: 0;
  }

  .sectionDivider {
    display: none;
  }

  .titleDivider {
    display: none;
  }

  .text:last-of-type {
    margin-bottom: 2rem;
  }

  .inerciseImg {
    margin-top: 0;
    width: 100%;
  }

  .endDivider {
    display: none;
  }

  .title {
    font-size: 6rem;
  }
}

.container {
  display: block;
  padding: 10rem 10% 4rem 10%;
  background-image: url(../../assets/images/inercise_advantages_section_bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

@media screen and (max-width: 1380px) {
  .container {
    background-repeat: repeat-y;
    background-size: contain;
    background-attachment: unset;
    background-position: center;
    padding: 10rem 2rem 4rem 2rem;
  }
}
